const openOverlay = (dispatch, content, header, isVideo, disableOnClickOutside) => {
    dispatch({ type: "OPEN_OVERLAY", payload: { content, header, isVideo, disableOnClickOutside } })
    document.body.classList.add("noScroll")
    return
}

const closeOverlay = dispatch => {
    dispatch({ type: "CLOSE_OVERLAY" })
    document.body.classList.remove("noScroll")
    return
}

const updateHeader = (dispatch, header) => {
    return dispatch({ type: "UPDATE_HEADER", payload: { header } })
}

export { openOverlay, closeOverlay, updateHeader }
