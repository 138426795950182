import "./src/styles/global.css"
import "./src/shareact/styles/icon-font.css"
import "./src/shareact/styles/toast.css"
import "intersection-observer"

import WrapPage from "./src/gatsby-api/wrap-page"
import WrapRoot from "./src/gatsby-api/wrap-root"

/* global exponea */

const onRouteUpdate = ({ location }) => {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "gatsby-route-change",
        })
        exponea.track("page_visit", {
            location: location.href,
            path: location.pathname,
        })
    }, 50)
}

const wrapRootElement = WrapRoot
const wrapPageElement = WrapPage

export { onRouteUpdate, wrapRootElement, wrapPageElement }
